	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	footer {
		margin: 0 auto;
		clear: both;
		background: #1c376c;
		padding: 0;
		position: relative;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 30px 0;
		}

		.wrapper {
			text-align: left;
			font-size: 18px;
			width: 670px;
			margin: 0;
			padding: 20px 0 18px 150px;
			line-height: 1.3;

			@media only screen and (max-width: 1250px) {
				padding: 20px 0 18px 70px;
			}

			@media only screen and (max-width: 990px) {
				width: 95%;
				padding: 0 5%;
				margin: 0 auto;
			}

			&:after {
				display: none;
			}

			&:before {
				@include before(535px, 303px);
				background: url(/i/design/footer-img.png) no-repeat center;
				right: -535px;
				bottom: 0;
				z-index: 9999;

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}

		}

		.footer-logo {
			display: inline-block;
			float: left;
			margin: 0 50px 0 0;
			transform: translateY(5px);

			@media only screen and (max-width: 990px) {
				float: none;
				display: block;
				margin: 0 auto 20px;
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0;
			color: #fff;

			span {
				display: block;
				font-weight: bold;
			}

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		a[href^="mailto:"] {
			display: inline-block;
			color: #84bbe7;
			margin: 5px 0 30px 0;
			position: relative;

			&:before {
				@include before(0, 2px);
				background: #84bbe7;
				bottom: 0;
				transition: .3s;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}

		#copyright {
			margin: 20px 25px 0 0;

			@media only screen and (max-width: 990px) {
				margin: 0 auto 5px;
			}
		}

		#copyright,
		#credit {
			font-size: 13px;
			display: inline-block;

			@media only screen and (max-width: 990px) {
				display: block;
			}

			a {
				color: #fff;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: #84bbe7;
					position: relative;

					&:before {
						@include before(0, 2px);
						background: #84bbe7;
						bottom: 0;
						left: 0;
						transition: .3s;
					}

					&:hover {
						&:before {
							width: 100%;
						}
					}
				}
			}
		}

		#map {
			position: absolute;
			width: 40%;
			height: 100%;
			top: 0;
			right: 0;

			@media only screen and (max-width: 1250px) {
				display: none;
			}
		}

	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.topper {
		position: absolute;
		z-index: 99999;
		bottom: 0;
		right: 0;

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}