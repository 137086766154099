// ----------------------------------- WELCOME SEC

#welcome-sec {
  padding: 80px 0;
  background: #fff url(/i/design/welcome-bg.jpg) no-repeat top center;
  position: relative;

  @media only screen and (max-width: 990px) {
    padding: 50px 0;
    background: #fff;
  }

  &:before {
    @include before(366px, 490px);
    background: url(/i/design/leaves.png) no-repeat center;
    right: 0;
    bottom: 50px;

    @media only screen and (max-width: 1800px) {
      right: -100px;
    }

    @media only screen and (max-width: 1700px) {
      right: -150px;
    }

    @media only screen and (max-width: 1600px) {
      right: -200px;
    }

    @media only screen and (max-width: 1300px) {
      display: none;
    }
  }

  .wrapper {
    width: 85%;

    @media only screen and (max-width: 1300px) {
      width: 90%;
    }

    @media only screen and (max-width: 990px) {
      width: 100%;
      padding: 0 5%;
    }
  }

  .wrap,
  .headerr {
    display: inline-block;
    vertical-align: middle;

    @media only screen and (max-width: 990px) {
      display: block;
    }
  }

  .headerr {
    width: 340px;
    margin: 0 60px 0 0;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.27);

    @media only screen and (max-width: 1300px) {
      margin: 0 40px 0 0;
    }

    @media only screen and (max-width: 990px) {
      margin: 0 auto 30px;
      width: 280px;
    }
  }

  .wrap {
    width: 55.5%;

    @media only screen and (max-width: 990px) {
      width: 100%;
    }
  }

  h2 {
    margin: 0 0 30px 0;
    text-transform: uppercase;
    color: #1c376c;
    font-size: 2vw;
    font-weight: 600;
    letter-spacing: 1px;

    @media only screen and (max-width: 990px) {
      text-align: center;
      margin: 0 auto 40px;
      font-size: 30px;
    }
  }

  p {
    font-size: 20px;
    margin: 0 0 30px 0;
    line-height: 1.2;

    @media only screen and (max-width: 1500px) {
      font-size: 18px;
      margin: 0 0 25px 0;
    }

    &:first-of-type {
      font-size: 1.5vw;


      @media only screen and (max-width: 1500px) {
        font-size: 22px;
      }
    }

    &:last-of-type {
      font-weight: 600;
    }
  }

  .read-more {
    float: right;
    display: inline-block;
    width: 280px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background: #1c376c;
    border-radius: 50px;
    font-size: 15px;
    margin: -60px 0 0 0;
    transition: .3s;

    &:hover {
      box-shadow: inset 0 0 0 2px #1c376c;
      color: #1c376c;
      background: #fff;
    }

    @media only screen and (max-width: 990px) {
      float: none;
      display: block;
      margin: 0 auto;
    }
  }
}

// ----------------------------------- STATS SEC

#animated-stats {
  padding: 80px 0 200px;
  background: url(/i/design/stats-bg.jpg) repeat-y top center;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 990px) {
    padding: 50px 0;
  }

  &:before {
    @include before(452px, 487px);
    background: url(/i/design/stats-right.png) no-repeat center;
    right: 0;
    bottom: 0;

    @media only screen and (max-width: 1800px) {
      right: -100px;
    }

    @media only screen and (max-width: 1700px) {
      right: -200px;
    }

    @media only screen and (max-width: 1600px) {
      right: -300px;
    }

    @media only screen and (max-width: 1300px) {
      display: none;
    }
  }

  &:after {
    @include before(447px, 748px);
    background: url(/i/design/stats-left.png) no-repeat center;
    left: 0;
    top: 0;

    @media only screen and (max-width: 1800px) {
      left: -100px;
    }

    @media only screen and (max-width: 1700px) {
      left: -200px;
    }

    @media only screen and (max-width: 1600px) {
      left: -300px;
    }

    @media only screen and (max-width: 1300px) {
      display: none;
    }
  }

  h2 {
    margin: 0 0 60px 0;
    text-transform: uppercase;
    color: #fff;
    font-size: 42px;
    font-weight: 600;

    @media only screen and (max-width: 990px) {
      text-align: center;
      margin: 0 auto 40px;
      font-size: 30px;
    }
  }

  .read-more {
    float: right;
    display: inline-block;
    width: 280px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #84bbe7;
    text-transform: uppercase;
    background: transparent;
    border-radius: 50px;
    font-size: 15px;
    margin: -110px 0 0 0;
    box-shadow: inset 0 0 0 2px #84bbe7;
    transition: .3s;

    &:hover {
      box-shadow: inset 0 0 0 2px #fff;
      color: #1c376c;
      background: #84bbe7;
    }

    @media only screen and (max-width: 990px) {
      float: none;
      display: block;
      margin: 0 auto 45px;
    }
  }

  ul {
    text-align: center;

    li {
      display: inline-block;
      font-size: 40px;
      width: 207px;
      height: 237px;
      background: #4b89ba url(/i/design/shield-mask.png) no-repeat center;
      margin: 0 18px;
      position: relative;
      // overflow: hidden;

      @media only screen and (max-width: 990px) {
        float: none;
        display: block;
        margin: 0 auto 145px;
      }

      &:after {
        @include before(100%, 100%);
        background: url(/i/design/shield-mask.png) no-repeat center;
        left: 0;
        bottom: 0;
        z-index: 1;
      }

      .rolling-digit {
        position: relative;
        display: inline-block;
        margin: 80px auto 0;
        z-index: 999;
        text-align: center;
        transform: translateX(-10px);
        color: #172d58;
        font-size: 50px;
        font-weight: 600;
      }

      span {
        position: relative;
        display: inline-block;
        margin: 0;
        z-index: 999;
        text-align: center;
        transform: translateX(-10px);
        color: #172d58;
        font-size: 50px;
        font-weight: 600;
      }
    }

    .descript {
      text-align: center;
      color: #fff;
      text-align: center;
      margin: 20px auto 0;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -60px;
      font-size: 20px;
      line-height: 1;
    }

    .animate-shadow {

      &:nth-of-type(1) {
        &:before {
          @include before(100%, 0);
          background: #84bbe7;
          left: 0;
          bottom: 0;
          z-index: 1;
          animation: bance 2s forwards .5s 1 ease;
        }
      }

      &:nth-of-type(2) {
        &:before {
          @include before(100%, 0);
          background: #84bbe7;
          left: 0;
          bottom: 0;
          z-index: 1;
          animation: bance2 2s forwards .6s 1 ease;
        }
      }

      &:nth-of-type(3) {
        &:before {
          @include before(100%, 0);
          background: #84bbe7;
          left: 0;
          bottom: 0;
          z-index: 1;
          animation: bance3 2s forwards .7s 1 ease;
        }
      }

      &:nth-of-type(4) {
        &:before {
          @include before(100%, 0);
          background: #84bbe7;
          left: 0;
          bottom: 0;
          z-index: 1;
          animation: bance4 2s forwards .8s 1 ease;
        }
      }
    }

    @keyframes bance {
      from {
        height: 0;
      }

      to {
        height: 85%;
      }
    }

    @keyframes bance2 {
      from {
        height: 0;
      }

      to {
        height: 67%;
      }
    }

    @keyframes bance3 {
      from {
        height: 0;
      }

      to {
        height: 76%;
      }
    }

    @keyframes bance4 {
      from {
        height: 0;
      }

      to {
        height: 93%;
      }
    }
  }
}

#btns-sec {
  ul {
    @extend .clearfix;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;

    li {
      padding: 0;
      margin: 0;
      background: none;
      overflow: hidden;
      position: relative;
      border: 5px solid #fff;

      &:before {
        @include before(100%, 200px);
        background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 1) 100%);
        bottom: 0;
        left: 0;
        pointer-events: none;
        user-select: none;
        z-index: 99;
      }

      &:first-of-type {
        width: 63%;
        height: 590px;
        float: left;

        @media only screen and (max-width: 990px) {
          display: block;
          float: none;
          width: 100%;
          height: 300px;
        }

        span {
          text-align: center;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      &:nth-of-type(2) {
        width: calc(100% - 63%);
        float: right;
        height: calc(300px - 5px);

        @media only screen and (max-width: 990px) {
          display: block;
          float: none;
          width: 100%;
          height: 300px;
        }
      }

      &:nth-of-type(3) {
        width: calc(100% - 63%);
        float: right;
        clear: right;
        height: calc(300px - 5px);

        @media only screen and (max-width: 990px) {
          display: block;
          float: none;
          width: 100%;
          height: 200px;
        }
      }

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        transition: .3s;
      }

      span {
        position: absolute;
        left: 50px;
        bottom: 40px;
        margin: 0;
        text-align: left;
        z-index: 99;
        color: #fff;
        font-size: 3vw;
        font-weight: 600;
        line-height: 1;
        text-transform: uppercase;

        @media only screen and (max-width: 990px) {
          text-align: center;
          font-size: 30px;
          margin: 0 auto;
          left: 0;
          right: 0;
        }
      }

      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
}

.photo-btn-cont {
  @extend .clearfix;
  margin: 0 0 50px 0;

  li {
    display: inline-block;
    margin: 0 2px 5px;
    padding: 0;
    background: none;
    position: relative;
    transition: .3s;
    overflow: hidden;
    width: calc(100% / 3 - 4px);
    float: left;

    @media only screen and (max-width: 1350px) {
      width: calc(100% / 2 - 4px);
    }

    @media only screen and (max-width: 990px) {
      width: auto;
      float: none;
      // display: block;
    }

    &:before {
      @include before(100%, 100px);
      background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(0, 0, 0, 1) 100%);
      bottom: 0;
      left: 0;
      pointer-events: none;
      user-select: none;
      transition: .8s;
    }

    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    span {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 20px;
      color: #fff;
      text-align: center;
      transition: .8s;
    }

    &:hover {
      transform: translateY(-2px);

      span, &:before { opacity: 0; }
    }

  }

  @media only screen and (max-width: 990px) {
    text-align: center;
  }
}

// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}