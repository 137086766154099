// -----------------------------------//
// DIARY
// -----------------------------------//
#diary-sec {
 padding: 80px 0;
 background: #1c376c;
 position: relative;

 @media only screen and (max-width: 990px) {
  padding: 50px 0;
 }

 &:before {
  @include before(198px, 494px);
  background: url(/i/design/leaves-right.png) no-repeat center;
  right: 0;
  top: 0;
 }

 &:after {
  @include before(201px, 500px);
  background: url(/i/design/leaves-left.png) no-repeat center;
  left: 0;
  bottom: 0;
 }

 h2 {
  margin: 0 0 50px 0;
  text-transform: uppercase;
  color: #fff;
  font-size: 42px;
  font-weight: 600;
  text-align: left;

  @media only screen and (max-width: 990px) {
   text-align: center;
   margin: 0 auto 40px;
   font-size: 30px;
  }
 }

 .btn-wrap {
  margin: -100px 0 180px 0;

  @media only screen and (max-width: 990px) {
   margin: 20px auto 40px;
  }
 }

 .read-more {
  float: right;
  display: inline-block;
  width: 250px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #84bbe7;
  text-transform: uppercase;
  background: transparent;
  border-radius: 50px;
  font-size: 15px;
  margin: 0 0 0 10px;
  box-shadow: inset 0 0 0 2px #84bbe7;
  transition: .3s;

  &:hover {
   box-shadow: inset 0 0 0 2px #fff;
   color: #fff;
   background: #84bbe7;
  }

  @media only screen and (max-width: 990px) {
   float: none;
   display: block;
   margin: 0 auto 5px;
  }
 }
}

//Diary
#SideHeadingDiary {

 ul.SideList {
  text-align: center;
  position: relative;
  z-index: 1;

  &:before {
   @include before(715px, 2px);
   background: url(/i/design/lines.png) no-repeat center;
   left: 0;
   right: 0;
   margin: 0 auto;
   top: 120px;
   z-index: -1;

   @media only screen and (max-width: 990px) {
    display: none;
   }
  }

  li {
   display: inline-block;
   padding: 0;
   width: 260px;
   background: none;
   transition: .3s;

   @media only screen and (max-width: 990px) {
    display: block;
    margin: 0 auto 30px;
   }

   &:nth-of-type(2) {
    margin: 0 90px;

    @media only screen and (max-width: 990px) {
     margin: 0 auto 30px;
    }
   }

   p.date {
    @include box(240px);
    background: #84bbe7;
    color: #162e5c;
    border-radius: 50%;
    padding: 65px 0 0 0;
    font-size: 60px;
    line-height: .8;
    margin: 0 auto 20px;
    float: none;
    font-weight: 600;

    @media only screen and (max-width: 990px) {
     @include box(200px);
     padding: 45px 0 0 0;
    }

    span {
     display: block;
    }
   }

   p.title {
    float: none;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    color: #fff;
    font-size: 18px;

    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 2;
     -webkit-box-orient: vertical;
     height: 50px;
     font-size: 18px;
    }
   }

   &:hover {
    p.date {
     box-shadow: 0 0 0 5px #fff;
    }
   }
  }

  &:hover {
   li:not(:hover) {
    transform: scale(.7);

    p.date {
     background: #fff;
    }
   }
  }
 }
}

// -----------------------------------//
// NEWS
// -----------------------------------//

#news-sec {
 padding: 60px 0 80px;
 background: #fff;
 position: relative;
 // overflow: hidden;

 @media only screen and (max-width: 990px) {
  padding: 50px 0;
 }

 h2 {
  margin: 0 0 60px 0;
  text-transform: uppercase;
  color: #1c376c;
  font-size: 42px;
  font-weight: 600;

  @media only screen and (max-width: 990px) {
   text-align: center;
   margin: 0 auto 40px;
   font-size: 30px;
  }
 }

 .read-more {
  float: right;
  display: inline-block;
  width: 280px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  background: #1c376c;
  border-radius: 50px;
  font-size: 15px;
  margin: -110px 350px 0 0;
  transition: .3s;

  &:hover {
   box-shadow: inset 0 0 0 2px #1c376c;
   color: #1c376c;
   background: #fafafa;
  }

  @media only screen and (max-width: 990px) {
   float: none;
   display: block;
   margin: 0 auto 45px;
  }
 }
}

//News
#SideHeadingNews {
 height: 500px;
 position: relative;
 overflow: hidden;

 @media only screen and (max-width: 990px) {
  height: auto;
 }

 &:before {
  @include before(400px, 100%);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  right: 0;
  top: 0;
  z-index: 99;
  pointer-events: none;
  user-select: none;

  @media only screen and (max-width: 1800px) {
   width: 300px;
  }

  @media only screen and (max-width: 1600px) {
   width: 200px;
  }

  @media only screen and (max-width: 1350px) {
   width: 100px;
  }

  @media only screen and (max-width: 990px) {
   width: 50px;
  }
 }

 &:after {
  @include before(50px, 100%);
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  left: calc(50% - 545px);
  top: 0;
  z-index: 999;
  pointer-events: none;
  user-select: none;
  visibility: visible;
  display: block;
  opacity: .5;
 }

 ul.SideList {
  padding: 0 400px 0 50px;
  height: 500px;
  margin: 0 0 0 calc(50% - 545px);
  overflow: hidden;
  white-space: nowrap;
  will-change: transform;
  user-select: none;
  pointer-events: all;
  vertical-align: top;

  @media only screen and (max-width: 1800px) {
   padding: 0 300px 0 50px;
  }

  @media only screen and (max-width: 1600px) {
   padding: 0 200px 0 50px;
  }

  @media only screen and (max-width: 1350px) {
   padding: 0 100px 0 50px;
  }

  @media only screen and (max-width: 990px) {
   padding: 0;
   margin: 0 auto;
   white-space: normal;
   height: auto;
   text-align: center;
  }

  li {
   display: inline-block;
   border-style: solid;
   border-width: 1px;
   border-color: rgb(137, 188, 228);
   box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.27);
   width: 310px;
   height: 450px;
   text-align: left;
   white-space: normal;
   transition: .3s;

   @media only screen and (max-width: 990px) {
    margin: 20px;
    width: 290px;
   }

   &:not(:last-of-type) {
    margin: 0 15px 0 0;

    @media only screen and (max-width: 990px) {
     margin: 15px;
    }
   }

   img {
    width: 308px;
    height: 196px;
    object-fit: cover;
   }

   p {
    padding: 20px 25px;
    line-height: 1.2;
    margin: 0;
   }

   p.date {
    padding: 0 25px;
    float: none;
    text-align: left;
    color: #1c376c;
    font-weight: 600;

    span {
     display: inline-block;
    }
   }

   p.title {
    color: #e30713;

    span {
     overflow: hidden;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     -webkit-box-orient: vertical;
     height: 30px;
     font-size: 30px;
    }
   }

   p.desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 100px;
    font-size: 17px;
   }

   &:hover {
    li:not(:hover) {
     opacity: .3;
     transform: scale(.95);
    }
   }

   @media only screen and (max-width: 990px) {

    &:nth-of-type(5),
    &:nth-of-type(6),
    &:nth-of-type(7),
    &:nth-of-type(8),
    &:nth-of-type(9),
    &:nth-of-type(10) {
     display: none;
    }
   }
  }
 }

 .active,
 .active a {
  cursor: grabbing !important;
  cursor: -webkit-grabbing;
 }
}

#btn {
 position: absolute;
 right: 0;
 transition: .3s;
 opacity: 0;

 @media only screen and (max-width: 990px) {
  display: none;
 }
}

#btn.show-me {
 opacity: 1;
}