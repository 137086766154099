header {
 padding: 40px 0 0 0;

 @media only screen and (max-width: 1800px) {
  padding: 60px 0 0 0;
 }

 @media only screen and (max-width: 1600px) {
  padding: 90px 0 0 0;
 }
}

header>.wrapper {
 padding: 0;
 position: relative;
 z-index: 60;
 width: 85%;
}

header h1 {
 margin: 0;
 background: none;
}

#logo-index {
 margin: 0;
 padding: 0;
 float: left;

 @media only screen and (max-width: 990px) {
  display: inline-block;
  float: none;
  margin: 0 auto 30px;
 }

 a {
  span {}

  img#logo {
   display: block;
   margin: 0 auto;
  }
 }
}

// ----------------------------------- SOCIAL DOCK

#social-dock {
 position: absolute;
 right: 12px;
 top: 50px;
 z-index: 99;

 @media only screen and (max-width: 990px) {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: auto;
 }

 li {
  display: block;
  padding: 0;
  background: none;
  margin: 0 0 25px 0;

  @media only screen and (max-width: 990px) {
   display: inline-block;
   margin: 0 5px;
  }

  &:first-of-type,
  &:nth-of-type(2) {
   @include box(25px);
  }

  img {
   display: inline-block;
   margin: 0 auto;
   vertical-align: top;
  }

  a {
   display: block;
   @include box(100%);
  }
 }
}

// ----------------------------------- GOOGLE TRANSLATE WIDGET

#google_translate_element {
 opacity: 0;
}

.translate {
 @include box(25px);
 overflow: hidden;
 background: url(/i/design/translate-ic.png) no-repeat center;
 z-index: 99;
}

.goog-te-gadget .goog-te-combo {
 padding: 10px;
}

// ----------------------------------- SEARCH FORM

a#FS {
 @include box(25px);
 background: url(/i/design/search-ic.png) no-repeat center;
 padding: 0px;
 transition: all .3s ease;
 cursor: pointer;
}

/* Search Style */
#search {
 position: fixed;
 top: 0px;
 left: 0px;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.9);
 transition: all 0.5s ease-in-out;
 transform: translate(0px, -100%) scale(0, 0);
 opacity: 0;
 display: none;
}

#search.open {
 transform: translate(0px, 0px) scale(1, 1);
 opacity: 1;
 z-index: 9999999;
 display: block;
}

#search input[type="search"] {
 position: absolute;
 top: 50%;
 left: 0;
 margin-top: -51px;
 width: 60%;
 margin-left: 20%;
 color: rgb(255, 255, 255);
 background: transparent;
 border-left: 0px solid transparent;
 border-right: 0px solid transparent;
 font-size: 40px;
 font-weight: 300;
 text-align: center;
 outline: none;
 padding: 10px;

 @media only screen and (max-width: 990px) {
  width: 70%;
  max-width: 250px;
  background: #fff;
  border-radius: 40px;
  padding: 20px;
  color: #111;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -50px auto 0;
  outline: none;
  box-shadow: 0 0 0 3px #cccccc;
 }
}